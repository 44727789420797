import { storage } from "./storage";

export default function allowAction(action) {
  const userInfo = storage.get("userInfo");
  if (userInfo) {
    const role = userInfo.role;
    if (role) {
      switch (role) {
        case "admin":
          return true;

        case "read_only": {
          if (action === "search" || action === "download") {
            return true;
          }
          return false;
        }
        case "text_edit_only": {
          if (
            action === "search" ||
            action === "download" ||
            action === "text-edit"
          ) {
            return true;
          }
          return false;
        }
        case "tag_edit_only": {
          if (
            action === "search" ||
            action === "download" ||
            action === "tag-edit"
          ) {
            return true;
          }
          return false;
        }
        case "text_tag_edit": {
          if (
            action === "search" ||
            action === "download" ||
            action === "text-edit" ||
            action === "tag-edit"
          ) {
            return true;
          }
          return false;
        }
        default:
          if (action === "search" || action === "download") {
            return true;
          }
          return false;
      }
    }
  }
  return false;
}
