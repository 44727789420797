import React from "react";
import "../css/tags.css";

const Tags = ({ tags, onClickTag, tagCategories }) => {
  return (
    <div className="search-tag-container">
      <p
        className="tags-title"
        style={{
          margin: 0,
        }}
      >
        {tags && tags.length > 0 ? "Tags:" : ""}
      </p>
      {tags &&
        tags.map((tag, i) => {
          return (
            <div
              className="tag-container"
              key={"Tags" + i}
              onClick={(e) => {
                e.stopPropagation();
                onClickTag(tag);
              }}
            >
              <p className="search-tag">
                {tag}
                {tagCategories[i] === "Jainism" || tagCategories[i] === "Topics"
                  ? " - " + tagCategories[i]
                  : ""}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default Tags;
