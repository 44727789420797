import React, { memo, Fragment } from "react";
import "./MultiCategorySelect.css";
function MultiCategorySelect(props) {
  return (
      <div
        className="multi-select-input"
        onClick={() => {
          props.setModalVisibility(true);
        }}
      >
        <span>{props.name}</span>
      </div>
  );
}
export default memo(MultiCategorySelect);
