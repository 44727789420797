import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { googleLogin } from "../utils/apiEndPoints";
import { GOOGLE_CLIENT_ID } from "../utils/constants";
import fetcher from "../utils/fetcher";
import Button from "../Views/Button";
import { ThemeContext } from "../utils/theme";
import { storage } from "../utils/storage";
import Header1 from "../Views/Header1";
const clientId = GOOGLE_CLIENT_ID;
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disabled: false,
      error: "",
    };
  }
  onAuthenticationSuccess = async (response) => {
    let userInfo = {
      email: response.profileObj.email,
      fname: response.profileObj.givenName,
      lname: response.profileObj.familyName,
      image: response.profileObj.imageUrl,
      token: response.tokenId,
    };
    fetcher(googleLogin + response.tokenId, "GET", null)
      .then((res) => {
        if (res.status === 200) {
          userInfo.role = res.details.role;
          storage.set("userInfo", userInfo);
          this.props.login();
          this.setState({
            disabled: false,
          });
        } else {
          this.setState({
            disabled: false,
            error: "Could not log you in",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  onAuthenticationFailure = async (response) => {
    this.setState({
      disabled: false,
    });
  };
  render() {
    const theme = this.context;
    const { disabled, error } = this.state;
    return (
      <div className="main-container">
        <Header1 />
        <p
          style={{
            margin: 0,
            fontWeight: 700,
            fontSize: 48,
            textAlign: "center",
          }}
        >
          Login
        </p>
        <div
          className="login-container"
          style={{
            borderColor: theme.gray,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GoogleLogin
              clientId={clientId}
              render={(renderProps) => {
                return (
                  <Button
                    backgroundColor={theme.primary}
                    disabledColor={theme.gray}
                    color={theme.white}
                    text="Sign in with Google"
                    onClick={() => {
                      this.setState({ disabled: true });
                      renderProps.onClick();
                    }}
                    disabled={renderProps.disabled || disabled}
                  />
                );
              }}
              buttonText="Login"
              onSuccess={this.onAuthenticationSuccess}
              onFailure={this.onAuthenticationFailure}
              cookiePolicy={"single_host_origin"}
            />
          </div>
          {error && (
            <p
              style={{
                textAlign: "center",
                color: "red",
              }}
            >
              {error}
            </p>
          )}
        </div>
      </div>
    );
  }
}
Login.contextType = ThemeContext;
