import React, { Component } from "react";
import Header2 from "../Views/Header2";
import GeneralCard from "../Views/GeneralCard";
import SatsangInfo from "../Views/SatsangInfo";
import setDataForFrontend from "../utils/setDataForFrontend";
import { storage } from "../utils/storage";
import fetcher from "../utils/fetcher";
import { search as searchEndPoint } from "../utils/apiEndPoints";
export default class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      pageNumber: 0,
      pageRecordSize: 5,
      loading: true,
      searchWord: "",
    };
  }
  componentDidMount() {
    let pageNumberResult = storage.getSession("pageNumber" + window.tabId);
    if (pageNumberResult) {
      this.setState({
        pageNumber: parseInt(pageNumberResult.pageNumber || 0),
        pageRecordSize: parseInt(pageNumberResult.pageRecordSize || 5),
      });
    }
    let detailsFromBackend = [];
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.state
    ) {
      this.setState({
        searchWord: this.props.location.state.searchWord,
      });
      detailsFromBackend = setDataForFrontend(this.props.location.state.state);

      this.setState({
        loading: false,
        results: detailsFromBackend,
      });
    } else {
      this.setState({
        loading: false,
        results: [],
      });
    }
  }
  componentWillUnmount() {
    storage.setSession("pageNumber" + window.tabId, {
      pageNumber: this.state.pageNumber,
      pageRecordSize: this.state.pageRecordSize,
    });
  }

  render() {
    const { results, pageNumber, pageRecordSize, loading } = this.state;
    const startIndex = results.length > 0 ? pageNumber * pageRecordSize : 0;
    let lastShowedIndex =
      results.length > 0 ? (pageNumber + 1) * pageRecordSize : 0;
    if (lastShowedIndex > results.length) {
      lastShowedIndex = results.length;
    }
    return (
      <div>
        <Header2
          updateData={(data) => {
            let detailsFromBackend = setDataForFrontend(data);
            this.setState({
              results: detailsFromBackend,
            });
          }}
          updateSearchWord={(word) => {
            this.setState({
              searchWord: word,
            });
          }}
        />
        {!loading && results && results.length > 0 && (
          <div
            style={{
              padding: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  margin: 0,
                }}
              >
                Showing {startIndex || 1} - {lastShowedIndex} of{" "}
                {results.length} items
              </p>
              <p
                style={{
                  margin: 0,
                }}
              >
                Items per page :{" "}
                <select
                  value={pageRecordSize}
                  onChange={(e) => {
                    this.setState({
                      pageRecordSize: e.target.value,
                    });
                  }}
                >
                  {[5, 10, 20, 30, 40, 50, 100].map((ele) => {
                    return (
                      <option value={ele} key={ele}>
                        {ele}
                      </option>
                    );
                  })}
                </select>
              </p>
            </div>
            {results &&
              results.length > 0 &&
              [...results].slice(startIndex, lastShowedIndex).map((ele, i) => {
                return (
                  <div
                    style={{ marginBottom: "45px", cursor: "pointer" }}
                    onClick={() => {
                      this.props.history.push(
                        "/details?satsang=" + ele.name + "&timeId=" + ele.id,
                        {
                          searchWord: this.state.searchWord,
                          title: ele.granthName + " " + ele.shibirName,
                        }
                      );
                    }}
                  >
                    <SatsangInfo
                      granthName={ele.granthName}
                      shibirName={ele.shibirName}
                      date={ele.date}
                      pravachanNo={ele.pravachanNo}
                      place={ele.place}
                    />
                    <GeneralCard
                      data={ele}
                      onClickTag={(tag) => {
                        let obj = {
                          tags: [tag],
                        };
                        fetcher(searchEndPoint, "POST", JSON.stringify(obj))
                          .then((res) => {
                            if (res) {
                              storage.setSession("pageNumber" + window.tabId, {
                                pageNumber: 0,
                                pageRecordSize: 5,
                              });
                              this.props.history.push("/results", {
                                state: res,
                                searchWord: "",
                              });
                            }
                          })
                          .catch((e) => console.log(e));
                      }}
                    />
                  </div>
                );
              })}
            <div
              style={{
                display: "flex",
                justifyContent: pageNumber > 0 ? "space-between" : "flex-end",
              }}
            >
              {pageNumber > 0 && (
                <p
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    this.setState({
                      pageNumber: pageNumber - 1,
                    });
                  }}
                >
                  Show Previous
                </p>
              )}
              {lastShowedIndex < results.length && (
                <p
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    this.setState({
                      pageNumber: pageNumber + 1,
                    });
                  }}
                >
                  Show Next
                </p>
              )}
            </div>
          </div>
        )}
        {!loading && results && results.length === 0 && (
          <div
            style={{
              textAlign: "center",
              marginTop: "10rem",
            }}
          >
            No data has been found for your search query
          </div>
        )}
      </div>
    );
  }
}
