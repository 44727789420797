import React, { useState, useContext, useEffect, useRef } from "react";
import "./search.css";
import TagsEdit from "./TagsEdit";
import Button from "../Views/Button";
import { ThemeContext } from "../utils/theme";
import allowAction from "../utils/allowActionAccordingToRole";
import getFormattedDate from "../utils/getFormattedDate";
import MultiCategorySelect from "./MultiCategorySelect";
const SearchCard = ({
  index,
  data,
  onClick,
  isSatsangDetailsCard,
  tagOptions,
  updateData,
  undoChanges,
  openTagsSelection,
}) => {
  const theme = useContext(ThemeContext);
  const [allTags, setAllTags] = useState(data.tags || []);
  let nameString = data.name;
  const [text, setText] = useState("");
  let times = getFormattedDate(data);
  const firstRender = useRef(null);
  useEffect(() => {
    if (!firstRender || !firstRender.current) {
      firstRender.current = true;
    } else {
      setAllTags(data.unsavedTags || []);
    }
  }, [data.unsavedTags]);
  if (times) {
    if (isSatsangDetailsCard) {
      nameString = `${times.startTime} - ${times.endTime}`;
    } else {
      nameString += ` : ${times.startTime} - ${times.endTime}`;
    }
  }

  return (
    <div
      className={"card-result"}
      onClick={() => {
        if (typeof onClick === "function") onClick();
      }}
      style={{
        marginTop: 10,
      }}
    >
      <div className="card-details edit">
        <div className="card-heading">
          <p className="result-name">
            {isSatsangDetailsCard && (
              <span className="card-number">#{index + 1}</span>
            )}
            {nameString}
          </p>
        </div>
        <div
          className="result-text"
          onInput={(e) => {
            if (allowAction("text-edit")) {
              setText(e.target.textContent);
            }
          }}
          contentEditable={allowAction("text-edit")}
        >
          {data.descriptionEdit
            ? data.descriptionEdit || data.description
            : data.text}
        </div>

        <p
          className="tags-title"
          style={{
            margin: allTags && allTags.length > 0 ? 0 : 10,
          }}
        >
          {allTags && allTags.length > 0 ? "Tags:" : ""}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            flexFlow: "wrap",
          }}
          className="tag-edit-parent"
        >
          {allowAction("tag-edit") ? (
            <React.Fragment>
              <TagsEdit
                tags={allTags}
                onRemovalTagClick={(i) => {
                  let tags = allTags.filter((e) => e !== i);
                  setAllTags(tags);
                }}
              />
              {/* <MultiCategorySelect
                name={"Add Keywords"}
                setModalVisibility={() => {
                  window.scrollTo(0, 0);
                  openTagsSelection();
                }}
              /> */}
            </React.Fragment>
          ) : null}
          <Button
            backgroundColor={theme.primary}
            disabledColor={theme.gray}
            color={theme.white}
            text="Save"
            onClick={() => {
              let newData = { ...data };
              if (text) {
                newData.descriptionEdit = text;
              }
              if (allTags) {
                newData.tags = allTags;
              }
              updateData(newData);
            }}
            width="3rem"
            style={{
              padding: "8px 15px",
              maxHeight: 30,
              margin: "0em 0em 0em 1em"
            }}
          />
          <Button
            backgroundColor={"#ffc9c9"}
            borderColor={"#ffc9c9"}
            disabledColor={theme.gray}
            color={theme.black}
            text="Undo"
            onClick={() => {
              undoChanges();
            }}
            width="3rem"
            style={{
              padding: "8px 15px",
              maxHeight: 30,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
