import React from "react";
import getFormattedDate from "../utils/getFormattedDate";
import "./search.css";
import Tags from "./Tags";

const SearchCard = ({
  index,
  data,
  onClick,
  isSatsangDetailsCard,
  onChangeHandler,
  checkboxChecked,
  onClickTag
}) => {
  let nameString = data.name;

  let times = getFormattedDate(data);
  if (times) {
    nameString = `${times.startTime} - ${times.endTime}`;
  }

  return (
    <div
      className={onClick ? "card-result card-with-onclick" : "card-result"}
      onClick={() => {
        if (typeof onClick === "function") onClick();
      }}
      style={{
        marginTop: 10,
      }}
    >
      <div className="card-details">
        <div className="card-heading">
          <p className="result-name">
            {isSatsangDetailsCard && (
              <span className="card-number">#{index + 1}</span>
            )}
            {nameString}
          </p>
          {isSatsangDetailsCard ? (
            <input
              type="checkbox"
              onChange={onChangeHandler}
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                width: 20,
                height: 20,
              }}
              checked={checkboxChecked}
            />
          ) : null}
        </div>
        <div
          className="result-text"
          dangerouslySetInnerHTML={{
            __html: data.description ? data.description : data.text,
          }}
        ></div>
        <Tags tags={data.tags} onClickTag={onClickTag} tagCategories={data.tagCategories}/>
        {!isSatsangDetailsCard ? (
          <p className="result-name">
            {isSatsangDetailsCard && (
              <span className="card-number">#{index + 1}</span>
            )}
            Satsang Code : {data.name}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default SearchCard;
