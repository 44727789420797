import React from "react";
export const themes = {
  primary: {
    name: "primary",
    primary: "#2F1160",
    white:'#fff',
    gray:'lightgray',
    lightPrimary:'#F7F7F7'
  },
  secondary: {
    name: "secondary",
    primary: "#2052a0",
    white:'#fff',
    gray:'lightgray',
    lightPrimary:'#2052a0'
  },
};
export const ThemeContext = React.createContext(themes.primary);
