import React, { Component } from "react";
import { ThemeContext } from "../utils/theme";
import Header1 from "../Views/Header1";
import Button from "../Views/Button";
import fetcher from "../utils/fetcher";
import { search as searchEndPoint } from "../utils/apiEndPoints";
import MultiCategorySelect from "../Views/MultiCategorySelect";
import { MultiSelect } from "react-multi-select-component";
import {
  getSatsangCodeOptions,
  getTagOptions,
  getCategoryNameOptions,
  getEventNameOptions,
  getGranthNameOptions,
  getPlaceNameOptions,
  getSatsangNameOptions,
  getShibirNameOptions,
} from "../utils/searchDropDownOptions";
import allowAction from "../utils/allowActionAccordingToRole";
import { storage } from "../utils/storage";
import TagSelection from "../Views/TagSelection";
export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      search: "",
      tagOptions: [],
      selectedTag: [],
      startDate: "",
      endDate: "",
      satsangCodeOptions: [],
      selectedSatsangCode: [],
      selectedGranth: [],
      granthOptions: [],
      selectedShibirName: [],
      shibirNameOptions: [],
      shibirNoOptions: [],
      selectedShibirNo: [],
      eventOptions: [],
      selectedEvent: [],
      placeOptions: [],
      selectedPlace: [],
      categoryOptions: [],
      selectedCategory: [],
      submitLoading: false,
      modalVisible: false,
      isFuzzy: false,
    };
    this.valueOnChange = this.valueOnChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  async componentDidMount() {
    getTagOptions(this.updateState)
      .then(() =>
        getSatsangCodeOptions(this.updateState, this.props.satsangCode)
      )
      .then(() => getEventNameOptions(this.updateState))
      .then(() => getPlaceNameOptions(this.updateState))
      .then(() => getCategoryNameOptions(this.updateState))
      .then(() => getGranthNameOptions(this.updateState))
      .then(() => getShibirNameOptions(this.updateState))
      .then(() => getSatsangNameOptions(this.updateState))
      .then(() =>
        this.setState({
          loading: false,
        })
      );
  }
  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  valueOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  submitClicked = () => {
    const {
      selectedEvent,
      selectedPlace,
      selectedCategory,
      selectedGranth,
      selectedShibirName,
      startDate,
      endDate,
      selectedTag,
      selectedSatsangCode,
      selectedShibirNo,
      search,
      isFuzzy,
    } = this.state;
    if (allowAction("search")) {
      let obj = {
        search,
        startDate: startDate ? Date.parse(new Date(startDate)) : null,
        endDate: endDate ? Date.parse(new Date(endDate)) : null,
        satsangCodes: this.props.satsangCode
          ? [this.props.satsangCode]
          : selectedSatsangCode.map((e) => e.value),
        tags: selectedTag,
        satsangs: selectedShibirNo.map((e) => e.value),
        shibirs: selectedShibirName.map((e) => e.value),
        granths: selectedGranth.map((e) => e.value),
        clusters: selectedCategory.map((e) => e.value),
        places: selectedPlace.map((e) => e.value),
        events: selectedEvent.map((e) => e.value),
        fuzzySearch: isFuzzy,
      };
      this.setState({
        submitLoading: true,
      });
      fetcher(searchEndPoint, "POST", JSON.stringify(obj))
        .then((res) => {
          if (res) {
            if (this.props.dontShowHeader) {
              this.props.updateData(res);
              this.props.updateSearchWord(search);
            } else {
              storage.setSession("pageNumber" + window.tabId, {
                pageNumber: 0,
                pageRecordSize: 5,
              });
              this.props.history.push("/results", {
                state: res,
                searchWord: search,
              });
            }
          }
          this.setState({ submitLoading: false });
        })
        .catch((e) => console.log(e));
    } else {
      alert("Your role does not have the permissions to search.");
    }
  };
  render() {
    const theme = this.context;
    const {
      search,
      tagOptions,
      selectedTag,
      satsangCodeOptions,
      selectedSatsangCode,
      selectedGranth,
      granthOptions,
      selectedShibirName,
      shibirNameOptions,
      selectedEvent,
      selectedShibirNo,
      eventOptions,
      shibirNoOptions,
      placeOptions,
      selectedPlace,
      categoryOptions,
      selectedCategory,
      startDate,
      endDate,
      modalVisible,
      loading,
      isFuzzy,
    } = this.state;
    const { dontShowHeader = false } = this.props;
    if (loading) {
      return (
        <div className="main-container">
          {!dontShowHeader && <Header1 />}
          <div
            style={{
              backgroundColor: dontShowHeader ? "white" : theme.lightPrimary,
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
            }}
          >
            <div
              className="search-div-parent"
              style={{
                backgroundColor: dontShowHeader ? "white" : theme.lightPrimary,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <svg
                version="1.1"
                id="L9"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                width="10rem"
                height="10rem"
              >
                <path
                  fill={theme.primary}
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="main-container"
        style={{
          display: "block",
          marginTop: 0,
        }}
      >
        <TagSelection
          modalVisible={modalVisible}
          tagOptions={tagOptions}
          selectedTag={selectedTag}
          close={() => {
            this.setState({
              modalVisible: false,
            });
          }}
          save={(selectedTag) => {
            this.setState({
              modalVisible: false,
              selectedTag,
            });
          }}
        />
        {!dontShowHeader && <Header1 />}
        <div className="search-div-parent-main">
          <div className="search-div-parent">
            <div
              className="main-search-box"
              style={{
                border: "2px solid " + theme.primary,
              }}
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.7812 13.8438L12.6562 10.7188C12.5 10.5938 12.3125 10.5 12.125 10.5H11.625C12.4688 9.40625 13 8.03125 13 6.5C13 2.9375 10.0625 0 6.5 0C2.90625 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.5 13C8 13 9.375 12.5 10.5 11.625V12.1562C10.5 12.3438 10.5625 12.5312 10.7188 12.6875L13.8125 15.7812C14.125 16.0938 14.5938 16.0938 14.875 15.7812L15.75 14.9062C16.0625 14.625 16.0625 14.1562 15.7812 13.8438ZM6.5 10.5C4.28125 10.5 2.5 8.71875 2.5 6.5C2.5 4.3125 4.28125 2.5 6.5 2.5C8.6875 2.5 10.5 4.3125 10.5 6.5C10.5 8.71875 8.6875 10.5 6.5 10.5Z"
                  fill="#B9B7BD"
                />
              </svg>

              <input
                type="text"
                placeholder="Search"
                name="search"
                value={search}
                onChange={this.valueOnChange}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    if (!this.state.disabled) this.submitClicked();
                  }
                }}
              />
            </div>
            <div className="select-row">
              <MultiCategorySelect
                name={
                  selectedTag.length > 0 ? "Keywords are selected" : "Keywords"
                }
                setModalVisibility={() => {
                  this.setState({
                    modalVisible: true,
                  });
                }}
              />
              <input
                type="date"
                name="startDate"
                date-data={startDate ? startDate : "Start Date"}
                value={startDate}
                style={{
                  borderColor: theme.primary,
                  color: theme.primary,
                }}
                onChange={this.valueOnChange}
              />
              <input
                type="date"
                date-data={endDate ? endDate : "End Date"}
                name="endDate"
                min={startDate ? startDate : ""}
                value={endDate}
                style={{
                  borderColor: theme.primary,
                  color: theme.primary,
                }}
                onChange={this.valueOnChange}
              />
              <MultiSelect
                options={satsangCodeOptions.map((ele) => {
                  return {
                    value: ele,
                    label: ele,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Satsang Codes",
                }}
                value={selectedSatsangCode ? selectedSatsangCode : []}
                onChange={(e) => {
                  this.setState({
                    selectedSatsangCode: e,
                  });
                }}
              />
            </div>
            <div className="select-row">
              <MultiSelect
                options={eventOptions.map((ele) => {
                  return {
                    value: ele,
                    label: ele,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Event",
                }}
                value={selectedEvent ? selectedEvent : []}
                onChange={(e) => {
                  this.setState({
                    selectedEvent: e,
                  });
                }}
              />
              <MultiSelect
                options={placeOptions.map((ele) => {
                  return {
                    value: ele,
                    label: ele,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Place",
                }}
                value={selectedPlace ? selectedPlace : []}
                onChange={(e) => {
                  this.setState({
                    selectedPlace: e,
                  });
                }}
              />
              <MultiSelect
                options={categoryOptions.map((ele) => {
                  return {
                    value: ele,
                    label: ele,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Cluster",
                }}
                value={selectedCategory ? selectedCategory : []}
                onChange={(e) => {
                  this.setState({
                    selectedCategory: e,
                  });
                }}
              />
            </div>
            <div className="select-row">
              <MultiSelect
                options={granthOptions.map((ele) => {
                  return {
                    value: ele.id,
                    label: ele.name,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Granth / Param Satsang / Udgosh",
                }}
                value={selectedGranth ? selectedGranth : []}
                onChange={(e) => {
                  let valArray = e.map((ele) => ele.value);
                  if (valArray.length > 0) {
                    getShibirNameOptions(this.updateState, valArray);
                    getSatsangNameOptions(this.updateState, valArray);
                  } else {
                    getShibirNameOptions(this.updateState, null);
                    getSatsangNameOptions(this.updateState, null);
                  }
                  this.setState({
                    selectedGranth: e,
                    selectedShibirName: [],
                    selectedShibirNo: [],
                  });
                }}
              />
              <MultiSelect
                options={shibirNameOptions.map((ele) => {
                  return {
                    value: ele.id,
                    label: ele.name,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Shibir Name",
                }}
                value={selectedShibirName ? selectedShibirName : []}
                onChange={(e) => {
                  let valArray = e.map((ele) => ele.value);
                  if (valArray.length > 0) {
                    getSatsangNameOptions(
                      this.updateState,
                      selectedGranth.map((e) => e.value),
                      valArray
                    );
                  } else {
                    getSatsangNameOptions(
                      this.updateState,
                      selectedGranth.map((e) => e.value),
                      null
                    );
                  }
                  this.setState({
                    selectedShibirName: e,
                    selectedShibirNo: [],
                  });
                }}
              />
              <MultiSelect
                options={shibirNoOptions.map((ele) => {
                  return {
                    value: ele.id,
                    label: ele.name,
                  };
                })}
                overrideStrings={{
                  selectSomeItems: "Satsang",
                }}
                value={selectedShibirNo ? selectedShibirNo : []}
                onChange={(e) => {
                  this.setState({
                    selectedShibirNo: e,
                  });
                }}
              />
            </div>
            <div className="select-row">
              <span
                style={{
                  color: theme.primary,
                }}
              >
                <input
                  type="checkbox"
                  checked={isFuzzy}
                  onChange={() => {
                    this.setState({
                      isFuzzy: !isFuzzy,
                    });
                  }}
                />{" "}
                Approximate Keyword Search?
              </span>
            </div>
            <div className="submit-row">
              <Button
                backgroundColor={theme.primary}
                disabledColor={theme.gray}
                color={theme.white}
                text="Search"
                onClick={this.submitClicked}
                width="10rem"
                disabled={this.state.submitLoading}
              />
              <p
                onClick={() => {
                  if (dontShowHeader) {
                    this.props.closeAccordion();
                  }
                  this.setState({
                    search: "",
                    selectedCategory: "",
                    selectedTag: [],
                    startDate: null,
                    endDate: null,
                    selectedSatsangCode: "",
                    selectedGranth: "",
                    selectedPravachanName: "",
                    selectedShibirName: "",
                    selectedShibirNo: "",
                    selectedEvent: "",
                    selectedPravachanNo: "",
                    selectedPlace: "",
                  });
                }}
                style={{
                  color: theme.primary,
                  marginLeft: 15,
                }}
              >
                Cancel
              </p>
            </div>
            {allowAction("uploadFile") ? (
              <div className="submit-row">
                <p
                  style={{
                    color: theme.primary,
                    marginLeft: 15,
                  }}
                  onClick={() => {
                    window.location.href = "/uploadFile";
                  }}
                >
                  Upload Files
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
Search.contextType = ThemeContext;
