export default function getFormattedDate(data) {
  if (
    data &&
    data.startTime !== undefined &&
    data.startTime !== null &&
    data.endTime &&
    data.endTime !== null &&
    data.endTime !== undefined
  ) {
    const formatter = (val) => {
      if (val < 10) return `0${val}`;
      else return val;
    };
    let startTime, endTime;
    const getTime = (timeinmilli) => {
      let hour = parseInt(timeinmilli / (60 * 60));
      let minutes = parseInt((timeinmilli % (60 * 60)) / (60));
      let seconds = timeinmilli % (60);
      return (
        formatter(hour) + ":" + formatter(minutes) + ":" + formatter(seconds)
      );
    };
    startTime = getTime(data.startTime);
    endTime = getTime(data.endTime);
    return {
      startTime,
      endTime,
    };
  }
  return null;
}
