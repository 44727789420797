export const googleLogin = "/auth?token=";
export const search = "/search";
export const getGranthNames = "/granths";
export const getShibirNames = "/shibirs";
export const getSatsangNames = "/satsangs";
export const getTagNames = "/tags";
export const getEventNames="/events";
export const getPlaceNames="/places";
export const getClusterNames = "/clusters";
export const getSatsangCodes="/satsangCodes";
export const update ="/satsang"