import React, { Component } from 'react';
import logo from "../images/logo.png";
export default class Header1 extends Component {
  render() {
    return (
      <div
        style={{
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            width: 102,
            height: 102,
            alignSelf: "center",
          }}
        />
        <p
          style={{
            margin: 0,
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Satsang Database
        </p>
      </div>
    );
  }
}
