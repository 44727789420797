import { AES } from "crypto-js";
import Utf8 from "crypto-js/enc-utf8";
import { APP_MODE, APP_STORAGE_SECRET } from "./constants";

export const storage = {
  set(key, value) {
    let setValue = JSON.stringify(value);
    if (APP_MODE === "release") {
      setValue = AES.encrypt(JSON.stringify(value), APP_STORAGE_SECRET).toString();
    }
    localStorage.setItem(key, setValue);
  },
  get(key) {
    let val = localStorage.getItem(key);
    let value = val;
    if (APP_MODE === "release" && value !== null) {
      value = AES.decrypt(val, APP_STORAGE_SECRET).toString(Utf8);
    }
    return JSON.parse(value);
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  setSession(key, value) {
    let setValue = JSON.stringify(value);
    if (APP_MODE === "release") {
      setValue = AES.encrypt(JSON.stringify(value), APP_STORAGE_SECRET).toString();
    }
    sessionStorage.setItem(key, setValue);
  },
  getSession(key) {
    let val = sessionStorage.getItem(key);
    let value = val;
    if (APP_MODE === "release" && value !== null) {
      value = AES.decrypt(val, APP_STORAGE_SECRET).toString(Utf8);
    }
    return JSON.parse(value);
  },
};
