export const APP_MODE = process.env.REACT_APP_MODE;
export const BASE_URL =
  APP_MODE === "dev"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL_PROD;
export const GOOGLE_CLIENT_ID =
  APP_MODE === "dev"
    ? process.env.REACT_APP_GOOGLE_ID_DEV
    : process.env.REACT_APP_GOOGLE_ID_PROD;
export const GOOGLE_CLIENT_SECRET =
  APP_MODE === "dev"
    ? process.env.REACT_APP_GOOGLE_CLIENT_SECRET_DEV
    : process.env.REACT_APP_GOOGLE_CLIENT_SECRET_PROD;

export const APP_STORAGE_SECRET =
  APP_MODE === "dev"
    ? process.env.REACT_APP_STORAGE_SECRET_DEV
    : process.env.REACT_APP_STORAGE_SECRET_PROD;
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
