import { months } from "../utils/constants";
export default function setDataForFrontend(data, tagDetails = {}) {
  let detailsFromBackend = [];
  data.forEach((ele) => {
    ele.content.forEach((e) => {
      let tags = e.tags;
      let tagCategories = [];
      tags.forEach((t) => {
        let index = Object.values(tagDetails).findIndex(
          (d, i) => d.findIndex((f) => f === t) !== -1
        );
        if (index !== -1) {
          tagCategories.push(Object.keys(tagDetails)[index]);
        } else {
          tagCategories.push("");
        }
      });
      let date = new Date(ele.satsangDate);
      detailsFromBackend.push({
        name: ele.satsangCode,
        description: e.text,
        descriptionEdit: e.textEdit || e.text,
        tags: e.tags,
        tagCategories: tagCategories,
        granthName: ele.granthName || "Not Available",
        date:
          date.getDate() +
          "-" +
          months[date.getMonth()] +
          "-" +
          date.getFullYear(),
        pravachanNo: ele.satsangNumber || "Not Available",
        shibirName: ele.shibirName || "Not Available",
        startTime: e.startTime,
        endTime: e.endTime,
        id: e.id,
        place: ele.place || "Not Available",
      });
    });
  });
  return detailsFromBackend;
}
