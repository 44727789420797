import React, { useState } from "react";
import { Fragment } from "react";
import logo from "../images/logo.png";
import "./header2.css";
import Search from "../ViewContainer/Search";

export default function Header2(props) {
  const [accordionOpen, setAccordion] = useState(false);
  return (
    <Fragment>
      <div className="second-header">
        <div
          className="second-header-1"
          onClick={() => (window.location = "/")}
          style={{ cursor: "pointer" }}
        >
          <img src={logo} alt="" />
          <p>Satsang Database</p>
        </div>
        <div className="second-header-2">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setAccordion(!accordionOpen);
            }}
          >
            <p>Advanced Search</p>
            <p
              style={{
                marginLeft: 10,
              }}
            >
              {accordionOpen ? "^" : "v"}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: accordionOpen ? "block" : "none",
        }}
        className="accordion-class"
      >
        <Search
          dontShowHeader={true}
          closeAccordion={() => {
            setAccordion(false);
          }}
          updateData={(data) => {
            setAccordion(false);
            props.updateData(data);
          }}
          updateSearchWord={(word) => {
            props.updateSearchWord(word);
          }}
          satsangCode={props.satsangCode}
        />
      </div>
    </Fragment>
  );
}
