import React from "react";
import "../css/tags.css";

const TagsEdit = ({ tags, onRemovalTagClick }) => {
  return (
    <div
      className="search-tag-container"
      style={{
        width: 440,
      }}
    >
      {tags && tags.length > 0 && (
        <div className="removable-tags-container">
          {tags.map((tag, i) => {
            return (
              <div
                className="tag-container"
                key={"Tags" + i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p className="search-tag">{tag} </p>
                <p
                  style={{
                    margin: "auto 10px",
                    cursor: "pointer",
                    fontSize: 14,
                  }}
                  onClick={() => {
                    onRemovalTagClick(tag);
                  }}
                >
                  x
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TagsEdit;
