import React, { Component } from "react";
export default class Button extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: this.props.disabled
            ? this.props.disabledColor
            : this.props.backgroundColor,
          color: this.props.color,
          width: this.props.width ? this.props.width : "45vw",
          borderColor: this.props.disabled
            ? this.props.disabledColor
            : this.props.borderColor || this.props.backgroundColor,
          borderWidth: 2,
          borderStyle: "solid",
          ...(this.props.style && this.props.style),
        }}
        onClick={() => {
          if (!this.props.disabled) {
            this.props.onClick();
          }
        }}
        role="button"
        className={
          (this.props.disabled ? "disabled-button " : "") + "custom-button"
        }
      >
        <p>{this.props.text}</p>
      </div>
    );
  }
}
