import React from "react";
import "../css/SatsangInfo.css";

const SatsangInfo = ({ granthName, shibirName, date, pravachanNo, place }) => {
  return (
    <div className="search-card-tags">
      <div className="metadata-info">
        {granthName} {shibirName}
      </div>
      <div className="metadata-info">Date: {date}</div>
      <div className="metadata-info">Pravachan Number: {pravachanNo}</div>
      <div className="metadata-info">Place: {place}</div>
    </div>
  );
};

export default SatsangInfo;
