import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header2 from "../Views/Header2";
import "../css/SatsangPage.css";
import GeneralCard from "../Views/GeneralCard";
import GeneralEditingCard from "../Views/GeneralEditingCard";
import Button from "../Views/Button";
import Modal from "../Views/Modal";
import {
  getTagNames,
  search as searchEndPoint,
  update,
} from "../utils/apiEndPoints";
import fetcher from "../utils/fetcher";
import { ThemeContext } from "../utils/theme";
import setDataForFrontend from "../utils/setDataForFrontend";
import html2pdf from "html2pdf.js";
import allowAction from "../utils/allowActionAccordingToRole";
import getFormattedDate from "../utils/getFormattedDate";
import TagSelection from "../Views/TagSelection";
import { storage } from "../utils/storage";
import toast, { Toaster } from 'react-hot-toast';
import { BASE_URL } from "../utils/constants";


const SatsangPage = ({ history, title }) => {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const [modalVisible, setModalVisbile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [tags, setTags] = useState(null);
  const [tagOptions, setTagOptions] = useState({});
  const [timeSelection, setTimeSelection] = useState([]);
  const [satsangContent, setSatsangContent] = useState([]);
  const [satsangCode, setSatsangCode] = useState("");
  const [editing, setEditing] = useState(null);
  const [generalEditingTag, setGeneralEditingTag] = useState(null);
  const [details, setDetails] = useState({});

  const toggleChecbox = (index, event) => {
    if (event.target.checked) {
      setTimeSelection([...timeSelection, index]);
    } else {
      let newTimeSelection = timeSelection.filter((ele) => ele !== index);
      setTimeSelection(newTimeSelection);
    }
  };
  const getTagOptions = useCallback(async () => {
    return fetcher(getTagNames)
      .then((res) => {
        if (res && res.length > 0) {
          let category = {};
          res.forEach((ele) => {
            if (!category[ele.categoryName]) category[ele.categoryName] = [];
            ele.tags.forEach((tag) => {
              category[ele.categoryName].push(tag.tag);
            });
          });
          setTagOptions(category);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    async function fetchTags() {
      await getTagOptions();
    }
    fetchTags();
  }, [getTagOptions]);

  const searchWithKeyword = useCallback(
    async (satsangCode1, search, data, timeId) => {
      return fetcher(
        searchEndPoint,
        "POST",
        JSON.stringify({ satsangCodes: [satsangCode1], search })
      )
        .then((res) => {
          if (res && res.length > 0) {
            let newSatsangContent = [...data];
            res.forEach((ele) => {
              ele.content.forEach((e) => {
                let index = newSatsangContent[0].content.findIndex(
                  (t) => t.id === e.id
                );
                if (index !== -1) {
                  let description = newSatsangContent[0].content[index].text;
                  newSatsangContent[0].content[index].text = e.text;
                  newSatsangContent[0].content[index].textEdit = description;
                }
              });
            });
            setSatsangContent(
              setDataForFrontend(newSatsangContent, tagOptions)
            );
            setLoading(false);
            if (data && data.length > 0 && timeId) {
              setTimeout(() => {
                document.getElementById(timeId).scrollIntoView();
              }, 300);
            }
          }
        })
        .catch((e) => console.log(e));
    },
    [tagOptions]
  );

  const searchWithoutKeyword = useCallback(
    async (satsangCode1, search, timeId) => {
      setTimeout(() => {
        return fetcher(
          searchEndPoint,
          "POST",
          JSON.stringify({ satsangCodes: [satsangCode1] })
        )
          .then((res) => {
            let data = [];
            if (res && res.length > 0) {
              setDetails(res[0]);
              setSatsangCode(satsangCode1);
              setSatsangContent(setDataForFrontend(res, tagOptions));
              data = res;
            }
            return data;
          })
          .catch((e) => console.log(e))
          .then((data) => {
            if (search)
              return searchWithKeyword(satsangCode1, search, data, timeId);
            else {
              setLoading(false);
              if (data && data.length > 0 && timeId) {
                setTimeout(() => {
                  document.getElementById(timeId).scrollIntoView();
                }, 300);
              }
            }
          });
      }, 1000);
    },
    [tagOptions, searchWithKeyword]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const satsangCode1 = searchParams.get("satsang");
    const timeId = searchParams.get("timeId");
    const search = location && location.state && location.state.searchWord;
    if (satsangCode1 != null) {
      return searchWithoutKeyword(satsangCode1, search, timeId);
    }
  }, [location, searchWithoutKeyword]);

  const updateCall = (data) => {
    let body = data.map((ele) => {
      return {
        id: ele.id,
        tags: ele.tags,
        text: ele.descriptionEdit,
      };
    });
    let newSatsangContent = [];
    satsangContent.forEach((ele) => {
      let newEle = { ...ele };
      if (ele.id === data[0].id) {
        newEle.tags = data[0].tags;
        newEle.description = data[0].descriptionEdit;
        newEle.descriptionEdit = data[0].descriptionEdit;
        newEle.unsavedTags = [];
      }
      newSatsangContent.push(newEle);
    });
    setSatsangContent(newSatsangContent);
    fetch(BASE_URL+update,{
      method:'PATCH',
      body:JSON.stringify(body),
      headers:{
        'Content-type':'application/json'
      }
    }).then(async res => {
      if (res.ok) {
        console.log(res)        
        toast.success("Successfully saved!")
      } else {
        const responseJson = await res.json()
        console.error(responseJson)
        toast.error(
        <p> Failed to save. Error:<br/>
          {JSON.stringify(responseJson, null, 4)}
        </p>, { duration: 30*1000, }) // 30 seconds
      }
    }).catch(error => {
      console.error(error)
      toast.error("Failed to update. Please try saving again.")
    })
  };

  const downloadSelected = () => {
    setEditing(null);
    if (satsangContent.length > 0) {
      downloadTxtFile();
    }
  };
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    let contentArray = [
      "Granth Name : " +
        details.granthName +
        "\nShibir : " +
        details.shibirName +
        "\nSatsang Name : " +
        details.satsangName +
        "\nEvent : " +
        (details.event || "NA") +
        "\nPlace : " +
        details.place +
        "\nDate : " +
        (details.satsangDate ? details.satsangDate.split("T")[0] : "") +
        "\n\n",
    ];
    satsangContent.forEach((ele, i) => {
      if (
        (timeSelection &&
          timeSelection.length > 0 &&
          timeSelection.indexOf(i) !== -1) ||
        !timeSelection ||
        timeSelection.length === 0
      ) {
        let times = getFormattedDate(ele);
        let dateString = "";
        if (times) {
          dateString = `${times.startTime} - ${times.endTime}`;
        }
        let tagString = "";
        if (ele.tags && ele.tags.length > 0) {
          tagString = `\nTags : ${ele.tags.join(", ")}`;
        }
        contentArray.push(`#${i + 1} ${dateString}\n${
          ele.descriptionEdit
        }${tagString}
      \n\n`);
      }
    });
    const file = new Blob(contentArray, {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = satsangCode + ".doc";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  };
  return (
    <div>
      <Toaster
        position="top-center"

      />
      <TagSelection
        modalVisible={modalVisible}
        tagOptions={tagOptions}
        selectedTag={
          generalEditingTag !== null && generalEditingTag !== undefined
            ? satsangContent[generalEditingTag].tags &&
              satsangContent[generalEditingTag].tags.length > 0
              ? satsangContent[generalEditingTag].tags
              : []
            : []
        }
        showSaveButton={
          generalEditingTag === null || generalEditingTag === undefined
            ? true
            : false
        }
        close={() => {
          setModalVisbile(false);
        }}
        save={(selectedTag) => {
          if (generalEditingTag !== null && generalEditingTag !== undefined) {
            let content = [...satsangContent];
            content[generalEditingTag].unsavedTags = selectedTag || [];
            setSatsangContent(content);
            setGeneralEditingTag(null);
          } else {
            if (selectedTag) {
              let newSatsangContent = [...satsangContent];
              let updatedData = [];
              timeSelection.forEach((ele) => {
                let content = newSatsangContent[ele];
                selectedTag.forEach((t) => {
                  if (content.tags.indexOf(t) === -1) {
                    content.tags.push(t);
                  }
                });

                updatedData.push(newSatsangContent[ele]);
              });
              setSatsangContent(newSatsangContent);
              updateCall(updatedData);
            }
            setTimeSelection([]);
          }
          setModalVisbile(false);
        }}
      />
      <Header2
        satsangCode={satsangCode}
        updateData={() => {}}
        updateSearchWord={(word) => {
          setLoading(true);
          searchWithoutKeyword(satsangCode, word);
        }}
      />
      <div className="satsang-details-container">
        <div className="satsang-heading">
          <p>
            {location && location.state && location.state.title
              ? location.state.title
              : satsangCode}
          </p>
          <div className="heading-action-bar">
            <div>
              {allowAction("tag-edit") ? (
                <Button
                  backgroundColor={theme.primary}
                  disabledColor={theme.gray}
                  color={theme.white}
                  text="Add Keywords"
                  onClick={() => setModalVisbile(true)}
                  disabled={timeSelection.length > 0 ? false : true}
                  width="10rem"
                />
              ) : null}
            </div>
            <div className="action-item">
              <Button
                backgroundColor={theme.primary}
                disabledColor={theme.gray}
                color={theme.white}
                text="Download"
                onClick={downloadSelected}
                width="10rem"
                disabled={satsangContent.length === 0}
              />
            </div>
          </div>
        </div>

        {!loading && satsangContent && satsangContent.length > 0 ? (
          <div id="search-body">
            {satsangContent.map((detail, index) => {
              if (editing === index) {
                return (
                  <div key={index} id={detail.id}>
                    <GeneralEditingCard
                      index={index}
                      data={detail}
                      isSatsangDetailsCard={true}
                      key={index}
                      tagOptions={tagOptions}
                      updateData={(newData) => {
                        updateCall([newData]);
                        setEditing(null);
                      }}
                      openTagsSelection={() => {
                        setGeneralEditingTag(index);
                        setModalVisbile(true);
                      }}
                      undoChanges={() => {
                        setEditing(null);
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} id={detail.id}>
                    <GeneralCard
                      index={index}
                      data={detail}
                      isSatsangDetailsCard={true}
                      onChangeHandler={(checked) =>
                        toggleChecbox(index, checked)
                      }
                      key={index}
                      checkboxChecked={
                        timeSelection.indexOf(index) !== -1 ? true : false
                      }
                      onClickTag={(tag) => {
                        let obj = {
                          tags: [tag],
                        };
                        fetcher(searchEndPoint, "POST", JSON.stringify(obj))
                          .then((res) => {
                            if (res) {
                              storage.setSession("pageNumber" + window.tabId, {
                                pageNumber: 0,
                                pageRecordSize: 5,
                              });
                              history.push("/results", {
                                state: res,
                                searchWord: "",
                              });
                            }
                          })
                          .catch((e) => console.log(e));
                      }}
                      onClick={() => {
                        if (allowAction("text-edit")) {
                          if (editing !== null) {
                            let content = [...satsangContent];
                            content[editing].unsavedTags = [];
                            setSatsangContent(content);
                          }
                          setEditing(index);
                        }
                      }}
                    />
                  </div>
                );
              }
            })}
          </div>
        ) : !loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10rem",
            }}
          >
            No data has been found for this satsang
          </div>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "10rem",
            }}
          >
            <svg
              version="1.1"
              id="L9"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              width="10rem"
              height="10rem"
            >
              <path
                fill={theme.primary}
                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
              >
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default SatsangPage;
