import React, { Component } from "react";
import setDataForFrontend from "../utils/setDataForFrontend";
import { storage } from "../utils/storage";
import Header2 from "../Views/Header2";
import Button from "../Views/Button";
import { ThemeContext } from "../utils/theme";
import upload from "../images/upload.png";
import error from "../images/error.png";
import success from "../images/success.png";
import loading from "../images/loader.gif";
import remove from "../images/remove.png";
import warning from "../images/warning.png";
import { BASE_URL } from "../utils/constants";
import fetcher from "../utils/fetcher";
import promiseLimit from "promise-limit";
let currentRequestCount = 0;
let queuedRequestArray = [];
export default class UpdateFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      files: [],
      processFiles: [],
      status: [],
      uploadDisable: false,
      message: "",
    };
    this.upload = this.upload.bind(this);
  }
  componentDidMount() {
    let userInfo1 = storage.get("userInfo");
    if (userInfo1 && userInfo1.role === "admin") {
      this.setState({
        admin: true,
      });
    }
  }
  async upload() {
    const { processFiles } = this.state;
    let status = [];
    for (let i = 0; i < processFiles.length; i++) {
      if (this.state.status && this.state.status.length > i) {
        if (this.state.status[i].success) {
          status.push({
            success: this.state.status[i].success,
          });
        } else {
          status.push({
            uploadLoading: true,
          });
        }
      } else {
        status.push({
          uploadLoading: true,
        });
      }
    }
    this.setState({
      status,
      uploadDisable: true,
    });
    const toUpload = [];
    let limit = promiseLimit(5);
    for (let i = 0; i < processFiles.length; i++) {
      let isAlreadyUploaded =
        this.state.status &&
        this.state.status.length > i &&
        this.state.status[i].success
          ? true
          : false;
      if (!isAlreadyUploaded) {
        const ele = processFiles[i];
        let form = new FormData();
        form.append("satsang", ele);
        toUpload.push({
          body: form,
          index: i,
        });
      }
    }
    toUpload.forEach((ele, index) => {
      this.queueUpRequest(
        ele.body,
        ele.index,
        status,
        index + 1 === toUpload.length
      );
    });
    const getStatus = () => {
      let interval = setInterval(() => {
        let filter = status.filter((e) => e.uploadLoading);
        if (filter && filter.length === 0) {
          clearInterval(interval);
          let filteredStatus = status.filter((e) => !e.success);
          if (filteredStatus.length === 0) {
            this.setState({
              processFiles: [],
              status: [],
              message: "All Uploads done successfully!",
            });
          }
        }
      }, 300);
    };
    getStatus();
  }
  queueUpRequest(body, index, status, lastIndex = false) {
    if (currentRequestCount < 5) {
      currentRequestCount++;
      fetch(BASE_URL + "/upload", {
        method: "POST",
        body: body,
      }).then(async (res) => {
        currentRequestCount--;
        if (queuedRequestArray.length > 0) {
          this.queueUpRequest(
            queuedRequestArray[0].body,
            queuedRequestArray[0].index,
            queuedRequestArray[0].status,
            queuedRequestArray[0].lastIndex
          );
          queuedRequestArray.shift();
        }
        if (res.status === 409) {
          status[index] = {
            error: "File already present",
            uploadLoading: false,
          };
        } else if (res.status === 201) {
          status[index] = {
            success: "Uploaded Successfully",
            uploadLoading: false,
          };
        } else if (res.status === 400) {
          let json = await res.json();
          status[index] = {
            errorList: json,
            uploadLoading: false,
          };
        }
        this.setState({
          status: status,
          ...(lastIndex && {
            uploadDisable: false,
          }),
        });
      }).catch(e=>{
        console.log(e)
      })
    } else {
      queuedRequestArray.push({
        body,
        index,
        status,
        lastIndex,
      });
    }
  }
  render() {
    const theme = this.context;
    const { processFiles } = this.state;
    if (!this.state.admin) {
      return <div>You are not allowed to access this page!</div>;
    }
    return (
      <div>
        <Header2
          updateData={(data) => {
            let detailsFromBackend = setDataForFrontend(data);
            this.setState({
              results: detailsFromBackend,
            });
          }}
          updateSearchWord={(word) => {
            this.setState({
              searchWord: word,
            });
          }}
        />
        <div className="update-file-main-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 10,
            }}
          >
            <input
              type="file"
              style={{ display: "none" }}
              multiple={true}
              id="file-input"
              onChange={(e) => {
                const filesss = e.target.files;
                const processFiles = Array.from(e.target.files);
                let count = 0;
                processFiles.forEach((element) => {
                  const extension =
                    "." +
                    element.name.split(".")[element.name.split(".").length - 1];
                  if (extension !== ".docx" && extension !== ".doc") {
                    count = 1;
                  }
                });
                if (count === 0) {
                  this.setState({
                    files: filesss,
                    processFiles: [...this.state.processFiles, ...processFiles],
                  });
                }
              }}
              accept={".docx,.doc"}
            />
            <label htmlFor="file-input">
              <Button
                backgroundColor={theme.primary}
                disabledColor={theme.gray}
                color={theme.white}
                text="Select"
                onClick={() => {}}
                disabled={false}
                width="10rem"
              />
            </label>
            <Button
              backgroundColor={theme.primary}
              disabledColor={theme.gray}
              color={theme.white}
              text="Upload"
              onClick={this.upload}
              disabled={processFiles.length === 0 || this.state.uploadDisable}
              width="10rem"
            />
          </div>
          <div
            style={{
              margin: 20,
            }}
          >
            {this.state.processFiles.map((ele, i) => {
              const statusObject =
                this.state.status && this.state.status.length > i
                  ? this.state.status[i]
                  : {};
              return (
                <div
                  key={ele.name + i}
                  style={{
                    border: "1px solid black",
                    padding: 10,
                    margin: "5px 0px",
                    borderRadius: 4,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>Name : {ele.name}</p>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {Object.keys(statusObject).length === 0 ? (
                        <p>
                          <img
                            src={upload}
                            alt="Upload Pending"
                            style={{
                              width: 22,
                            }}
                          />
                        </p>
                      ) : statusObject.uploadLoading ? (
                        <p>
                          <img
                            src={loading}
                            alt="Uploading..."
                            style={{
                              width: 22,
                            }}
                          />
                        </p>
                      ) : statusObject.success ? (
                        <p>
                          <img
                            src={success}
                            alt="Uploaded Successfully"
                            style={{
                              width: 22,
                            }}
                          />
                        </p>
                      ) : statusObject.error ? (
                        <p>
                          <img
                            src={warning}
                            alt="Upload Warning"
                            style={{
                              width: 22,
                            }}
                          />
                        </p>
                      ) : (
                        <p>
                          <img
                            src={error}
                            alt="Upload Error"
                            style={{
                              width: 22,
                            }}
                          />
                        </p>
                      )}
                      <p
                        onClick={() => {
                          if (!ele.uploadLoading) {
                            let newProcessFiles = [],
                              newStatus = [];
                            processFiles.forEach((ele, index) => {
                              if (i !== index) {
                                newProcessFiles.push(ele);
                                if (
                                  this.state.status &&
                                  this.state.status.length > 0
                                ) {
                                  newStatus.push(this.state.status[index]);
                                }
                              }
                            });
                            this.setState({
                              processFiles: newProcessFiles,
                              status: newStatus,
                            });
                          }
                        }}
                      >
                        <img
                          src={remove}
                          alt="Remove"
                          style={{
                            width: 18,
                            marginLeft: 14,
                            cursor: "pointer",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                  {statusObject.error && (
                    <div
                      style={{
                        fontSize: 14,
                        color: "orange",
                      }}
                    >
                      {statusObject.error}
                    </div>
                  )}
                  {statusObject.errorList && (
                    <div
                      style={{
                        fontSize: 14,
                        color: "red",
                      }}
                    >
                      {statusObject.errorList.map((e, index) => {
                        return (
                          <p
                            style={{
                              marginTop: 0,
                              marginBottom: 5,
                            }}
                          >
                            {index + 1}. Line number {e.lineNumber} - {e.error}
                          </p>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
            {this.state.processFiles.length === 0 && this.state.message ? (
              <div>{this.state.message}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
UpdateFile.contextType = ThemeContext;
