import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { storage } from "../utils/storage";
import { ThemeContext, themes } from "../utils/theme";
import Login from "../ViewContainer/Login";
import SatsangPage from "../ViewContainer/SatsangPage";
import Search from "../ViewContainer/Search";
import SearchResult from "../ViewContainer/SearchResult";
import UpdateFile from "../ViewContainer/UpdateFile";
function PrivateRoute({ children, ...rest }) {
  const [authenticated, setAuthentication] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let userInfo1 = storage.get("userInfo");
    if (userInfo1) {
      setAuthentication(true);
    }
    setLoading(false);
  }, [setAuthentication]);
  if (loading) {
    return <div></div>;
  }
  if (authenticated) {
    return children;
  } else {
    return (
      <Login
        login={() => {
          setAuthentication(true);
        }}
      />
    );
  }
}

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: themes.primary,
    };
  }
  componentDidMount() {
    window.tabId = Math.floor(Math.random() * 1000000);
  }
  render() {
    const { theme } = this.state;
    return (
      <ThemeContext.Provider value={theme}>
        <PrivateRoute>
          <BrowserRouter>
            <Switch>
              <Route path="/" exact render={(props) => <Search {...props} />} />

              <Route
                path="/results"
                exact
                render={(props) => <SearchResult {...props} />}
              />

              <Route path="/details" render={(props) => <SatsangPage {...props} />} />
                
              <Route path="/uploadFile">
                <UpdateFile />
              </Route>
            </Switch>
          </BrowserRouter>
        </PrivateRoute>
      </ThemeContext.Provider>
    );
  }
}
