import {
  getGranthNames,
  getSatsangNames,
  getShibirNames,
  getTagNames,
  getEventNames,
  getPlaceNames,
  getClusterNames,
  getSatsangCodes,
} from "../utils/apiEndPoints";
import fetcher from "./fetcher";

export async function getTagOptions(updateState) {
  return fetcher(getTagNames)
    .then((res) => {
      if (res && res.length > 0) {
        let category = {};
        res.forEach((ele) => {
          if (!category[ele.categoryName]) category[ele.categoryName] = [];
          ele.tags.forEach((tag) => {
            category[ele.categoryName].push(tag.tag);
          });
        });
        updateState("tagOptions", category);
      }
    })
    .catch((e) => console.log(e));
}
export async function getSatsangCodeOptions(updateState, defaultSatsangCode) {
  return fetcher(getSatsangCodes)
    .then((res) => {
      if (res && res.length > 0) {
        if (defaultSatsangCode) {
          updateState(
            "satsangCodeOptions",
            res.filter((t) => t === defaultSatsangCode)
          );
        } else {
          updateState("satsangCodeOptions", res);
        }
      }
    })
    .catch((e) => console.log(e));
}
export async function getEventNameOptions(updateState) {
  return fetcher(getEventNames)
    .then((res) => {
      if (res && res.length > 0) {
        updateState("eventOptions", res);
      }
    })
    .catch((e) => console.log(e));
}
export async function getPlaceNameOptions(updateState) {
  return fetcher(getPlaceNames)
    .then((res) => {
      if (res && res.length > 0) {
        updateState("placeOptions", res);
      }
    })
    .catch((e) => console.log(e));
}
export async function getCategoryNameOptions(updateState) {
  return fetcher(getClusterNames)
    .then((res) => {
      if (res && res.length > 0) {
        updateState("categoryOptions", res);
      }
    })
    .catch((e) => console.log(e));
}
export async function getGranthNameOptions(updateState) {
  return fetcher(getGranthNames)
    .then((res) => {
      if (res && res.length > 0) {
        updateState("granthOptions", res);
      }
    })
    .catch((e) => console.log(e));
}
export async function getShibirNameOptions(updateState, granthName = null) {
  let attach = "";
  if (granthName) {
    attach = "?granths=" + granthName;
  }
  return fetcher(getShibirNames + attach)
    .then((res) => {
      if (res && res.length > 0) {
        updateState("shibirNameOptions", res);
      }
    })
    .catch((e) => console.log(e));
}
export async function getSatsangNameOptions(
  updateState,
  granthName = null,
  shibirName = null
) {
  let attach = "";
  if (granthName) {
    attach = "?granths=" + granthName;
  }
  if (shibirName) {
    attach = "?shibirs=" + shibirName;
  }
  return fetcher(getSatsangNames + attach)
    .then((res) => {
      if (res && res.length > 0) {
        updateState("shibirNoOptions", res);
      }
    })
    .catch((e) => console.log(e));
}
