import React, { memo, useState, useMemo, useEffect, useContext } from "react";
import Modal from "../Views/Modal";
import Button from "./Button";
import "./TagSelection.css";
import { ThemeContext } from "../utils/theme";
function TagSelection(props) {
  const { modalVisible, tagOptions, close, save } = props;
  const [selectedTag, setSelectedTag] = useState(props.selectedTag);
  const [selectedKeywords, setSelectedKeyWords] = useState([]);
  const [search, setSearch] = useState("");
  const keywordOptions = useMemo(() => {
    if (tagOptions) {
      return Object.keys(tagOptions);
    }
    return [];
  }, [tagOptions]);
  useEffect(() => {
    setSelectedTag(props.selectedTag);
  }, [props.selectedTag]);
  const allTagOptions = useMemo(() => {
    if (selectedKeywords && selectedKeywords.length > 0) {
      let tags = [];
      selectedKeywords.forEach((ele) => {
        tags.push(
          ...tagOptions[ele].filter(
            (e) => e.toLowerCase().indexOf(search.toLowerCase()) !== -1
          )
        );
      });
      return tags;
    } else {
      let tags = [];
      for (let tag in tagOptions) {
        tags.push(
          ...tagOptions[tag].filter(
            (e) => e.toLowerCase().indexOf(search.toLowerCase()) !== -1
          )
        );
      }
      return tags;
    }
  }, [tagOptions, selectedKeywords, search]);
  const theme = useContext(ThemeContext);
  return (
    <Modal
      modalVisible={modalVisible}
      onClose={() => {
        close();
        setSelectedTag([]);
        setSelectedKeyWords([]);
      }}
    >
      <div className="parent">
        <div
          style={{
            display: "flex",
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Button
            backgroundColor={theme.primary}
            disabledColor={theme.gray}
            color={theme.white}
            text="Save"
            onClick={() => {
              save(selectedTag);
              setSelectedTag([]);
              setSelectedKeyWords([]);
            }}
            width="10rem"
            disabled={false}
            style={{
              marginLeft:5,
              padding:'5px 6px'
            }}
          />
        </div>
        <div className="body">
          <div className="keywords">
            {keywordOptions.map((ele) => {
              const selected = selectedKeywords.indexOf(ele) !== -1;
              return (
                <p
                  title={ele}
                  className={selected ? "selected-keyword" : ""}
                  onClick={() => {
                    if (selected) {
                      let a = selectedKeywords.filter((e) => e !== ele);
                      setSelectedKeyWords(a);
                    } else {
                      setSelectedKeyWords([...selectedKeywords, ele]);
                    }
                  }}
                >
                  {ele}
                </p>
              );
            })}
          </div>
          <div className="tags">
            {allTagOptions.map((ele) => {
              return (
                <p title={ele}>
                  <input
                    type="checkbox"
                    checked={selectedTag.indexOf(ele) !== -1 ? true : false}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        let tags = selectedTag.filter((tag) => tag !== ele);
                        setSelectedTag(tags);
                      } else {
                        setSelectedTag([...selectedTag, ele]);
                      }
                    }}
                  />
                  {ele}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
}
export default memo(TagSelection);
