import React, { memo } from "react";
function Modal(props) {
  if (props.modalVisible) {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: document.body.clientHeight,
          display: "flex",
          justifyContent: "center",
          top: 0,
        }}
      >
        <div className="modal-container"></div>
        <div className="container">
          <div className="close-button" onClick={props.onClose}>
            <p>x</p>
          </div>
          <div className="modal-parent">{props.children}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
export default memo(Modal);
