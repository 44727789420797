import { BASE_URL } from "./constants";

export default async function fetcher(url,method,body,headers={
    'Content-type':'application/json'
}){
    if(method === 'GET'){
        return fetch(BASE_URL+url,{
            method:method,
            headers:headers
        }).then(res=>res.json())
    }
    return fetch(BASE_URL+url,{
        method:method,
        body:body,
        headers:headers
    }).then(res=>res.json())
}